import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69159de9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "operate-pancel" }
const _hoisted_3 = { class: "btnbox" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "btn" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aliplayer = _resolveComponent("aliplayer")!
  const _component_player = _resolveComponent("player")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_picker = _resolveComponent("van-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.options.source)
      ? (_openBlock(), _createBlock(_component_aliplayer, {
          key: 0,
          options: _ctx.options,
          style: {"position":"relative"}
        }, null, 8, ["options"]))
      : _createCommentVNode("", true),
    (_ctx.options.url)
      ? (_openBlock(), _createBlock(_component_player, {
          key: 1,
          options: _ctx.options
        }, null, 8, ["options"]))
      : _createCommentVNode("", true),
    _createVNode(_component_van_cell_group, null, {
      default: _withCtx(() => [
        _createVNode(_component_van_cell, {
          "title-class": "row-title",
          title: "设备名称",
          value: _ctx.$route.query.name
        }, null, 8, ["value"]),
        (!!_ctx.videoUrl)
          ? (_openBlock(), _createBlock(_component_van_cell, {
              key: 0,
              "title-class": "row-title",
              title: "监控点",
              "is-link": "",
              clickable: "",
              onClick: _ctx.fShowPopup
            }, {
              value: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.monitorLocation), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (!_ctx.videoUrl)
          ? (_openBlock(), _createBlock(_component_van_cell, {
              key: 1,
              "title-class": "row-title",
              title: "监控点",
              clickable: "",
              value: "无"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_van_cell, {
        "title-class": "row-title",
        title: "控制台",
        style: {"background":"transparent"}
      }),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.btnList, (btn, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "btnbox-grid",
            key: index,
            onClick: ($event: any) => (_ctx.fmonitorSet(btn.command))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                src: 
                require('../../../assets/visualmonitor/' + btn.icon + '.png')
              
              }, null, 8, _hoisted_6)
            ])
          ], 8, _hoisted_4))
        }), 128))
      ])
    ]),
    _createVNode(_component_van_popup, {
      show: _ctx.popupShow,
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.popupShow) = $event)),
      position: "bottom",
      round: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_picker, {
          title: "选择监控点",
          columns: _ctx.pickerColumns,
          onConfirm: _ctx.onConfirm,
          onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.popupShow = false))
        }, null, 8, ["columns", "onConfirm"])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}