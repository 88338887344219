
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/visualmonitor";
import player from "@/components/mpegts/index.vue";
import aliplayer from "@/components/aliplayer/index.vue";

@Options({
  name: "Monitorplay",
  components: {
    player,
    aliplayer,
  },
})
export default class extends Vue {
  btnList = [
    {
      icon: "left-top",
      command: 5,
    },
    {
      icon: "top",
      command: 3,
    },
    {
      icon: "right-top",
      command: 7,
    },
    {
      icon: "narrow",
      command: 10,
    },
    {
      icon: "enlarge",
      command: 9,
    },
    {
      icon: "left",
      command: 1,
    },
    {
      icon: "push",
      command: 99,
    },
    {
      icon: "right",
      command: 2,
    },
    {
      icon: "enlarge-dec",
      command: 11,
    },
    {
      icon: "enlarge-add",
      command: 12,
    },
    {
      icon: "left-down",
      command: 6,
    },
    {
      icon: "down",
      command: 4,
    },
    {
      icon: "right-down",
      command: 8,
    },
    {
      icon: "square-dec",
      command: 14,
    },
    {
      icon: "square-add",
      command: 13,
    },
  ];
  channel = 1;
  videoUrl = "";
  options = {};
  popupShow = false;
  pickerColumns: string[] = [];
  monitorLocation = "监控点0";
  mounted() {
    this.createPlayer();
  }
  createPlayer() {
    // if (this.$route.query.typeOf === "ios") {
      // 苹果
      api
        .getVsURL({
          deviceId: this.$route.query.id,
          channel: this.$route.query.channel,
          protocol: "hls",
        })
        .then((res: any) => {
          this.videoUrl = res.data.url;
          this.options = {
            isLive: true,
            preload: false, // 播放器自动加载，目前仅h5可用。
            source: this.videoUrl,
          };
        });
    // } else if (this.$route.query.typeOf === "android") {
      // 安卓
    //   api
    //     .getVsURL({
    //       deviceId: this.$route.query.id,
    //       channel: this.channel,
    //       protocol: "flv",
    //     })
    //     .then((res: any) => {
    //       this.videoUrl = res.data.url;
    //       this.options = {
    //         type: "flv",
    //         isLive: true,
    //         preload: false, // 播放器自动加载，目前仅h5可用。
    //         url: this.videoUrl,
    //       };
    //     });
    // }
  }
  onConfirm(param: string) {
    this.monitorLocation = param;
    this.popupShow = false;
    this.channel = this.pickerColumns.indexOf(this.monitorLocation) + 1;
    this.createPlayer();
  }
  fShowPopup() {
    let num = parseInt(this.$route.query.way as string);
    for (let i = 0; i < num; i++) {
      this.pickerColumns.push("监控点" + i);
    }
    this.popupShow = true;
  }
  fmonitorSet(param: number) {
    if (param !== 99) {
      this.ptz(0, param, 2);
      setTimeout(() => {
        this.ptz(1, param);
      }, 1000);
    } else {
      // this.ptz(1,this.command)
    }
  }

  ptz(action: number, command: number, speed?: number) {
    let data = {
      deviceId: this.$route.query.id,
      action: action,
      command: command,
      speed: speed,
      channel: this.$route.query.channel,
    };
    api.ptz(data);
  }
}
